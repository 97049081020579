
import { Component, Prop, Vue } from "vue-property-decorator";
import { DocumentsTable } from "housfy-ui-lib";
import DocumentsPageContentShimmer from "@/components/protected/DocumentsPageContentShimmer.vue";
import { IDocumentFormatted } from "@/models/Documents";
import HousfySvg from "@/components/ui/HousfySvg.vue";

@Component({
  name: "DocumentsPageContent",
  components: {
    DocumentsTable,
    DocumentsPageContentShimmer,
    HousfySvg,
  },
})
export default class DocumentsPageContent extends Vue {
  @Prop({ type: Array, default: () => [] })
  documents!: IDocumentFormatted[];
  @Prop({ type: String, required: true })
  title!: string;
  @Prop({ type: String, default: "" })
  subtitle?: string;
  @Prop({ type: Boolean, default: false })
  pageIsLoading?: boolean;
}
